import Dashboard from './component/dashboard/Dashboard.js';
import Login from './component/login/Login.js';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Navigation() {
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(loggedIn) {
            navigate('/dashboard');
        } else {
            navigate('/userLogin');
        }
    }, [loggedIn, navigate]);

    return(
        <Routes>
            <Route path="/userLogin" element={<Login setLoggedIn={setLoggedIn}/>}/>
            <Route path="/dashboard" element={<Dashboard loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}/>
        </Routes>
    )
}

function App() {
    return(
        <BrowserRouter>
            <Navigation />
        </BrowserRouter>
    )
}
export default App;
