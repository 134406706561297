// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-brand-login {
    color: #58585B;
    font-size: 30px;
    vertical-align: middle;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
  
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      background-color: #58585B;
      height: 40px;
      top: 3px;
      left: 0;
    }
}

.dashboard-navbar {
    background: linear-gradient(90deg,#0D274D .29%,#1E4471 100%);
    justify-content: left;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    height: 80px;
  }`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;;IAEjB;MACE,kBAAkB;MAClB,WAAW;MACX,UAAU;MACV,yBAAyB;MACzB,YAAY;MACZ,QAAQ;MACR,OAAO;IACT;AACJ;;AAEA;IACI,4DAA4D;IAC5D,qBAAqB;IACrB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,YAAY;EACd","sourcesContent":[".title-brand-login {\n    color: #58585B;\n    font-size: 30px;\n    vertical-align: middle;\n    position: relative;\n    padding-left: 20px;\n    margin-left: 10px;\n  \n    &:before {\n      position: absolute;\n      content: \"\";\n      width: 1px;\n      background-color: #58585B;\n      height: 40px;\n      top: 3px;\n      left: 0;\n    }\n}\n\n.dashboard-navbar {\n    background: linear-gradient(90deg,#0D274D .29%,#1E4471 100%);\n    justify-content: left;\n    align-items: center;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 50px;\n    height: 80px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
