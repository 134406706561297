//-------------POLLING UTILITY--------------------------------
import { useEffect, useRef } from 'react';
export function useInterval(callback, delay){
    const savedCallback = useRef();

    useEffect(()=> {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick(){
            savedCallback.current();
        }
        if(delay !== null){
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
//------------FETCH DATA UTILITY------------------------------
export async function fetchData(query, timeRange, setData, setIsLoading, shouldSetLoading = true){
    if (shouldSetLoading) {
        setIsLoading(true);
    }
    const user = JSON.parse(localStorage.getItem("user"));
    let headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'jwt-token': user.token
    };

    let params = new URLSearchParams({
        "keyWord": query,
        "time": timeRange
    });

    let requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_URL}/search?` + params.toString(), requestOptions)
        .then(response => {
            if(response.ok){
                setIsLoading(false);
                return response.json();
            } else if(response.status === 503){
                // If status is 503, wait for 5 seconds and call fetchData again
                setTimeout(() => fetchData(query, timeRange, setData, setIsLoading), 5000);
                throw new Error('Server is not ready yet.');
            } else{
                setIsLoading(false);
                alert(`No records found`);
                throw new Error('No Records Found');
            }
        })
        .then(result => {
            if(result.length !== 0){
                console.log(`Result received of length : ${result.length}`)
                setData(result);
            } else {
                setData([]);
            }
        })
        .catch(error => {
            if(error.message !== 'Server is not ready yet.' && shouldSetLoading){
                setIsLoading(false);
                alert(`Error while searching for records ${query}  :: ${error}`)
            }
        });
}
//-------TABLE DATA FUNCTIONS------------------------------------
export function getGroupedData(data) {
    const tempGroupedData = {};
    const tempLinks = {};
    data.forEach((doc)=>{
        const id = doc.id;
        const record = doc.record;
        if(!tempGroupedData[record.app]) {
            tempGroupedData[record.app] = {};
        }
        if(!tempGroupedData[record.app][record.code]) {
            tempGroupedData[record.app][record.code] = [];
        }
        if(!tempLinks[record.app]) {
            tempLinks[record.app] = {};
        }
        if(!tempLinks[record.app][record.code]) {
            tempLinks[record.app][record.code] = [];
        }
        tempLinks[record.app][record.code].push({
            "runbook": record.actions.runbook,
            "selfheal": record.actions.selfheal
        })
        tempGroupedData[record.app][record.code].push({
            "id" : id,
            "level" : record.level,
            "host" : record.host,
            "timestamp" : new Date(parseInt(record.timestamp)).toLocaleString(),
            "originalTimestamp": record.timestamp,
            "alerted" : (record.alerted==="true") ? "ALERTED" : "NOT ALERTED",
            "alertId" : record.alertId,
            "resolved" : record.resolved
        });
    });
    return {groupedData: tempGroupedData, links: tempLinks};
}

export function timestampSort(rowA, rowB) {
    return rowB.originalTimestamp - rowA.originalTimestamp;
}
//------------------------------------------------------------------------------------

export const getNextDate = (date)=>{
    const dateArr = date.split("-");
    let day = parseInt(dateArr[2]);
    let month = parseInt(dateArr[1]);
    let year = parseInt(dateArr[0]);

    if(day < 28){
        day++;
    }else{
        if(month === 2 && (day===28 || day===29)){
            if(year%4===0){
                if(day ===28)day++;
                else{
                    day = 1;
                    month++;
                }
            }else{
                day = 1;
                month++;
            }
        } else if(month === 1 || month === 3 || month === 5 || month ===7 || month === 8 || month === 10 || month === 12 ){
            if(day === 31){
                day = 1;
                if(month === 12){month = 1; year++}
                else month++;
            }else day++;
        } else {
            if(day === 30){
                day = 1;
                month++;
            }else day++;
        }
    }

    let dateStr = "";
    dateStr += year.toString()+"-";
    dateStr += (month < 10)?`0${month}-`:`${month}-`;
    dateStr += (day < 10)?`0${day}`:`${day}`;
    return dateStr;
}

export const getPrevDate = (date)=>{
    const dateArr = date.split("-");
    let day = parseInt(dateArr[2]);
    let month = parseInt(dateArr[1]);
    let year = parseInt(dateArr[0]);
    if(day > 1){
        day--;
    }else{
        if(month === 3){
            if(year%4===0){
                month--;
                day = 29;
            }else{
                month--;
                day = 28;
            }
        }else if(month === 2 || month === 4 || month === 6 || month === 8 || month === 9 || month === 11 || month === 1){
            if(month === 1){
                year--;
                month = 12;
                day = 31;
            }else{
                month--;
                day = 31;
            }
        } else {
            month--;
            day=30;
        }
    }

    let dateStr = "";
    dateStr += year.toString()+"-";
    dateStr += (month < 10)?`0${month}-`:`${month}-`;
    dateStr += (day < 10)?`0${day}`:`${day}`;
    return dateStr;
}
