// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/background/diamond-sunset.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-svg::before {
    content: "";
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: repeat;
    pointer-events: none; 
    opacity: 0.5;
    z-index: -1;
  }
  
  .background-svg {
    position: relative;
  }`, "",{"version":3,"sources":["webpack://./src/styles/background.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,wDAA+D;IAC/D,yBAAyB;IACzB,oBAAoB;IACpB,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".background-svg::before {\n    content: \"\";\n    position: absolute;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-image:url('../assets/background/diamond-sunset.svg');\n    background-repeat: repeat;\n    pointer-events: none; \n    opacity: 0.5;\n    z-index: -1;\n  }\n  \n  .background-svg {\n    position: relative;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
