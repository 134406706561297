import {
  Box,
  Flex,
  useColorModeValue,
  Heading,
  Text,
  Image,
  Divider
} from '@chakra-ui/react';
import '../../styles/navbar.css'

export default function Navbar() {
  return (
      <Flex 
      className='dashboard-navbar'
      gap={4}>
          <Image
          height={'30px'}
          objectFit='contain'
          src={process.env.PUBLIC_URL + '/cisco_spaces_logo_inline.svg'}
          alt='Cisco DNASpaces Logo'/>
      </Flex>
  );
}