// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@keyframes blink {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

.blinking {
  animation: blink 2s linear infinite;
}

@keyframes alert {
  0% {background-color: white;}
  50% {background-color: rgb(253, 135, 135);}
  100% {background-color: white;}
}

.alert {
  animation-name: alert;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":";;AAEA;EACE,IAAI,UAAU,CAAC;EACf,KAAK,UAAU,CAAC;EAChB,MAAM,UAAU,CAAC;AACnB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,IAAI,uBAAuB,CAAC;EAC5B,KAAK,oCAAoC,CAAC;EAC1C,MAAM,uBAAuB,CAAC;AAChC;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,sCAAsC;EACtC,4BAA4B;AAC9B","sourcesContent":["\n\n@keyframes blink {\n  0% {opacity: 0;}\n  50% {opacity: 1;}\n  100% {opacity: 0;}\n}\n\n.blinking {\n  animation: blink 2s linear infinite;\n}\n\n@keyframes alert {\n  0% {background-color: white;}\n  50% {background-color: rgb(253, 135, 135);}\n  100% {background-color: white;}\n}\n\n.alert {\n  animation-name: alert;\n  animation-duration: 0.7s;\n  animation-timing-function: ease-in-out;\n  animation-iteration-count: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
