import { Flex, 
    Box, 
    VStack, 
    FormControl, 
    InputGroup, 
    InputLeftElement, 
    InputRightElement,
    Input, 
    Spacer, 
    Button, 
    FormErrorMessage, 
    Modal, 
    useDisclosure,
    Text,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Image,
    ModalCloseButton,
    Heading} from '@chakra-ui/react';
    import { FaUserAlt, FaLock } from "react-icons/fa";
    import { useState } from 'react';

export default function LoginModal({isOpen, onClose, username, setUsername, usernameError, password, setPassword, passwordError, handleLogin}){
    const [showPassword, setShowPassword] = useState(false);
    const handleShowClick = () => setShowPassword(!showPassword);

    return(
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={'3xl'}>
            <ModalOverlay />
            <ModalContent height={'650px'} p={10}>
                <ModalHeader
                alignContent={'center'}
                pt={10}
                pb={20}>
                <Flex justifyContent="center" width="100%">
                    <Image
                    width={'180px'}
                    objectFit='contain'
                    src={process.env.PUBLIC_URL + '/cisco_spaces_logo.svg'}
                    alt='Cisco DNASpaces Logo'/>
                </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction="column" align="center" width="100%">
                        <Flex justifyContent={'flex-start'} width="80%" pb={'30px'}> 
                            <Text className='login-modal-header'>Login</Text>
                        </Flex>
                        <VStack
                        as="form"
                        spacing={4} // Changed from "gap" to "spacing" for consistency
                        width="80%" // Set your desired width here
                        alignItems="center" // Center the form controls
                        onSubmit={handleLogin} // Add the submit event handler here
                        >
                            <FormControl isInvalid={usernameError}>
                                <InputGroup>
                                    <InputLeftElement
                                    pointerEvents="none"
                                    children={<FaUserAlt color="rgba(200,200,200,1)" />}
                                    />
                                    <Input 
                                    type="username" 
                                    placeholder="Username"
                                    onChange={e => setUsername(e.target.value)}
                                    value={username}/>
                                </InputGroup>
                                {usernameError && <FormErrorMessage>Please enter a username</FormErrorMessage>}
                            </FormControl>
                            <FormControl isInvalid={passwordError}>
                                <InputGroup>
                                    <InputLeftElement
                                    pointerEvents="none"
                                    children={<FaLock color="rgba(200,200,200,1)" />}
                                    />
                                    <Input 
                                    type={showPassword ? "text" : "password"} 
                                    placeholder="Password"
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}/>
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                                        {showPassword ? "Hide" : "Show"}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                {passwordError && <FormErrorMessage>Please enter a password</FormErrorMessage>}
                            </FormControl>
                            <Spacer />
                            <Button
                                borderRadius={5}
                                type="submit"
                                variant="solid"
                                colorScheme="blue"
                                width="full"
                                height={'50px'}
                            >
                                <Text className='login-modal-continue'>Continue</Text>
                            </Button>
                        </VStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}