import { VStack, Input, Text, IconButton, HStack, Tooltip, Container, Select } from '@chakra-ui/react'
import { QuestionOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import { fetchData } from '../../utils/Utility';
import { TiMediaPlayOutline , TiMediaPauseOutline } from "react-icons/ti";
import '../../styles/App.css';

export default function Filters({setData,setLastUpdated, query, setQuery, timeRange, setTimeRange, liveMode, setLiveMode, isLoading, setIsLoading, setSelectedRows}){

    const handleFormSubmit = (e)=>{
        e.preventDefault();
        const currentTime = new Date().getTime();
        setLastUpdated(currentTime);
        setSelectedRows([]);
        fetchData(query, timeRange, setData, setIsLoading);
    }

    const toolTipInfo = "Input can either be the App Name, AppName:ErrorCode or AlertId"
    return(
        <Container 
            maxW={'90vw'}
            alignItems={'left'}>
            <form onSubmit={e => handleFormSubmit(e)}>
                <HStack width={"100%"}>
                    <VStack width={"100%"} alignItems={'left'}>
                        <HStack gap={'1px'}>
                            <Text width={'8vw'}>Search Query</Text>
                            <Tooltip label={toolTipInfo} placement='top-end'>
                                <QuestionOutlineIcon/>
                            </Tooltip>
                        </HStack>
                        <Input 
                        placeholder='App Name | AppName:ErrorCode | AlertID' 
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        isDisabled={isLoading}/>
                    </VStack>
                    <VStack width={"40%"} alignItems={'left'}>
                        <Text width={'8vw'}>Time Range</Text>
                        <Select 
                        placeholder="Select time range"
                        value={timeRange}
                        onChange={e =>setTimeRange(e.target.value)}
                        isDisabled={isLoading}>
                            <option value="min5">Last 5 mins</option>
                            <option value="min15">Last 15 mins</option>
                            <option value="min30">Last 30 mins</option>
                            <option value="hour1">Last 1 hour</option>
                            <option value="hour24">Last 24 hours</option>
                            <option value="day7">Last 7 days</option>
                            <option value="day30">Last 30 days</option>
                        </Select>
                    </VStack>
                    <Tooltip label='Search' placement='top'>
                        <IconButton
                            marginTop={7}
                            type='submit'
                            aria-label='Search database'
                            size={'md'}
                            variant={'solid'}
                            fontSize='20px'
                            colorScheme='blue'
                            isDisabled={isLoading}
                            icon={<SearchIcon />}
                        />
                    </Tooltip>
                    <Tooltip label='Live Mode' placement='top-end'>
                            <IconButton
                                icon={liveMode ?  <TiMediaPauseOutline/> : <TiMediaPlayOutline/>}
                                marginTop={7}
                                size={'md'}
                                fontSize='30px'
                                onClick={()=>{setLiveMode(!liveMode)}}
                                isDisabled={isLoading}
                                colorScheme={liveMode ? 'red' : 'green'}
                                className={liveMode ? 'blinking' : ''}
                            />
                    </Tooltip>
                </HStack>
            </form>
        </Container>
    
    )
}