import { Box, Container, Flex, Heading, VStack, Text, Input, Button, InputGroup, InputLeftElement, InputRightElement, FormControl, Spacer, FormErrorMessage,  Alert, AlertIcon, AlertTitle, AlertDescription, useDisclosure } from "@chakra-ui/react";
import {useEffect, useState} from "react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoginNavbar from "./LoginNavbar";
import '../../styles/background.css';
import '../../styles/login.css';
import LoginModal from "./LoginModal";

export default function Login({setLoggedIn}){

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [incorrectAuth, setincorrectAuth] = useState(false);
    const navigate = useNavigate();


    const handleShowClick = () => setShowPassword(!showPassword);

    const handleLogin = (e) =>{
        e.preventDefault();
        setPasswordError(false);
        setUsernameError(false);
        if(password==="" && username===""){
            setUsernameError(true);
            setPasswordError(true);
        } else if(password==="" || username===""){
        if(password==="")setPasswordError(true);
        if(username==="")setUsernameError(true);
        } else{
            verifyLogin();
        }

    }

    const verifyLogin = ()=>{
        fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password})
        })
        .then(response => {
            if(response.ok){
                setLoggedIn(true);
                return response.json();
            } else if(response.status === 401){
                setLoggedIn(false);
                setincorrectAuth(true);
                throw new Error('Incorrect credentials');
            } else{

            }
        })
        .then(r => {
            localStorage.setItem("user", JSON.stringify({username, token: r.token}));
            navigate('/dashboard');
        })
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if(!user || !user.token){
            setLoggedIn(false);
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/verify`, {
            method: "POST",
            headers: {
                'jwt-token': user.token
            }
        })
        .then(response => {
            if(response.ok){
                setLoggedIn(true);
                navigate('/dashboard');
                return;
            } else{
                setLoggedIn(false);
                return;
            }
        })
    }, []);
    
    return (
        <div className="background-svg">
            <LoginNavbar/>
            <Flex className="login-container">
                <Button
                borderRadius={5}
                type="submit"
                variant="solid"
                bg="#1d80f7"
                color="white"
                _hover={{ bg: "skyblue" }}
                onClick={onOpen}
                size={'lg'}
                width={'140px'}
                height={'50px'}>
                    <Text className='login-button-text'>Login</Text>
                </Button>
            </Flex>
            <LoginModal
                isOpen={isOpen}
                onClose={onClose}
                username={username}
                password={password}
                setUsername={setUsername}
                setPassword={setPassword}
                usernameError={usernameError}
                passwordError={passwordError}
                handleLogin={handleLogin}/>
        </div>
      );
}